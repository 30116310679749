<template>
  <v-row>
    <v-card class="pa-6 mt-n8" width="100%" flat>
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="7" sm="7">
            <v-btn icon class="mt-n3" @click="cancel()">
              <v-icon> mdi-less-than </v-icon>
            </v-btn>
            <span class="text-h4"> Untitled - {{ today }} </span>
          </v-col>
          <v-col cols="12" md="2" sm="2" class="text-right mt-n1">
            <v-select
              v-model="status"
              outlined
              :items="statusItems"
              item-value="name"
              background-color="white"
              style="border-radius: 0px"
              disabled
            >
              <template v-slot:selection="{ item }">
                <span v-if="item.name == 'เปิดใช้งาน'" style="color: #11c872">
                  <v-icon style="color: #11c872"> mdi-circle </v-icon>
                  <v-icon style="color: #11c872"> mdi-blank </v-icon>
                  {{ item.name }}
                </span>
                <span v-if="item.name == 'ปิดใช้งาน'" style="color: red">
                  <v-icon style="color: red"> mdi-circle </v-icon>
                  <v-icon style="color: red"> mdi-blank </v-icon>
                  {{ item.name }}
                </span>
                <span v-if="item.name == 'ฉบับร่าง'" style="color: #026aa2">
                  <v-icon style="color: #026aa2"> mdi-circle </v-icon>
                  <v-icon style="color: #026aa2"> mdi-blank </v-icon>
                  {{ item.name }}
                </span>
              </template>
              <template v-slot:item="{ item }">
                <span v-if="item.name == 'เปิดใช้งาน'" style="color: #11c872">
                  <v-icon style="color: #11c872"> mdi-circle </v-icon>
                  <v-icon style="color: #11c872"> mdi-blank </v-icon>
                  {{ item.name }}
                </span>
                <span v-if="item.name == 'ปิดใช้งาน'" style="color: red">
                  <v-icon style="color: red"> mdi-circle </v-icon>
                  <v-icon style="color: red"> mdi-blank </v-icon>
                  {{ item.name }}
                </span>
                <span v-if="item.name == 'ฉบับร่าง'" style="color: #026aa2">
                  <v-icon style="color: #026aa2"> mdi-circle </v-icon>
                  <v-icon style="color: #026aa2"> mdi-blank </v-icon>
                  {{ item.name }}
                </span>
              </template>
            </v-select>
          </v-col>
          <v-divider class="mt-2" vertical style="height: 56px"></v-divider>
          <v-col cols="12" md="1" sm="1" class="text-right">
            <v-btn
              @click="saveDraft('q_1_1')"
              disabled
              text
              color="#2ab3a3"
              style="
                border: solid 1px #2ab3a3;
                border-radius: 0px;
                padding: 12px 16px;
                gap: 8px;
                width: 114px;
                height: 48px;
              "
              >บันทึกฉบับร่าง</v-btn
            >
          </v-col>
          <v-col cols="12" md="2" sm="2" class="text-right">
            <v-btn
              @click="submit('q_1_1')"
              color="#2AB3A3"
              dark
              elevation="0"
              style="
                border-radius: 0px;
                padding: 12px 16px;
                gap: 8px;
                width: 114px;
                height: 48px;
              "
            >
              บันทึก
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-tabs centered fixed-tabs>
      <v-tab class="text-h6"> เนื้อหา </v-tab>
      <v-tab class="text-h6"> SEO </v-tab>
      <v-tabs-slider color="#2AB3A3"> </v-tabs-slider>

      <v-tab-item class="pa-6">
        <!-- <v-col cols="12" md="12" sm="12" class="pa-6 mt-10">
          <span style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          ">
            เพิ่มข่าวและกิจกรรมคณะ
          </span>
        </v-col> -->
        <v-form
          ref="createForm"
          v-model="checkValidate"
          :lazy-validation="lazy"
        >
          <v-card class="pa-6" width="100%">
            <v-tabs v-model="e2">
              <v-col
                cols="6"
                md="3"
                sm="3"
                :style="
                  e2 == 0
                    ? 'border-bottom: 3px solid #2ab3a3'
                    : 'border-bottom: 1px solid #2ab3a3'
                "
              >
                <v-tab class="textResponsive"> ภาษาไทย </v-tab>
              </v-col>
              <v-col
                cols="6"
                md="3"
                sm="3"
                :style="
                  e2 == 1
                    ? 'border-bottom: 3px solid #2ab3a3'
                    : 'border-bottom: 1px solid #2ab3a3'
                "
              >
                <v-tab class="textResponsive"> English </v-tab>
              </v-col>
              <v-col
                cols="6"
                md="6"
                sm="6"
                style="border-bottom: 1px solid #2ab3a3"
              >
              </v-col>
              <v-tabs-slider color="#2AB3A3"> </v-tabs-slider>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="12" sm="12"
                        ><span class="pt-2">หัวข้อ (TH)</span>
                        <span style="color: red">*</span></v-col
                      >
                      <v-col cols="12" md="12" sm="12">
                        <v-text-field
                          hide-details
                          v-model="form.title_th"
                          placeholder="ระบุหัวข้อ (TH)"
                          dense
                          outlined
                          :rules="rules.required"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="12" sm="12"
                        ><span class="pt-2">เนื้อหา (TH)</span>
                        <span style="color: red">*</span></v-col
                      >
                      <v-col cols="12" md="12" sm="12">
                        <quill-editor
                          v-model="form.full_desc_th"
                          :content="content"
                          :options="editorOption"
                          @change="onEditorChange($event)"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="12" sm="12"
                        ><span class="pt-2">หัวข้อ (English)</span>
                        <span style="color: red">*</span></v-col
                      >
                      <v-col cols="12" md="12" sm="12">
                        <v-text-field
                          hide-details
                          v-model="form.title_en"
                          placeholder="ระบุหัวข้อ (English)"
                          dense
                          outlined
                          :rules="rules.required"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="12" sm="12"
                        ><span class="pt-2">เนื้อหา (English)</span>
                        <span style="color: red">*</span></v-col
                      >
                      <v-col cols="12" md="12" sm="12">
                        <quill-editor
                          id="discipline-qe"
                          v-model="form.full_desc_en"
                          :content="content1"
                          :options="editorOption"
                          @change="onEditorChange1($event)"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card>

          <!-- <v-col class="mt-5">
        <span style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          ">แกลลอรี่</span>
      </v-col> -->

          <v-row>
            <v-col cols="12" md="12" sm="12" class="pa-6 mt-10">
              <span
                style="
                  font-family: 'IBM Plex Sans Thai';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 24px;
                  line-height: 40px;
                "
              >
                แกลลอรี่
              </span>
            </v-col>
          </v-row>

          <v-card class="pa-6" width="100%">
            <v-row>
              <v-col
                v-for="(item, indexItemPic) in this.showImageUpload"
                :key="indexItemPic"
                class="d-flex child-flex"
                cols="4"
              >
                <v-img
                  :src="item.path"
                  contain
                  max-width="400px"
                  min-height="400px"
                >
                  <v-row>
                    <v-col>
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-col>
                    <v-col class="text-right">
                      <v-btn
                        x-small
                        style="
                          border: solid 1px;
                          border-radius: 0px;
                          opacity: 0.4;
                        "
                        @click="pictureDelete(indexItemPic)"
                        >X</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <VueFileAgent
                  ref="vueFileAgent"
                  :theme="'grid'"
                  :multiple="true"
                  :deletable="true"
                  :meta="true"
                  :accept="'image/*'"
                  :helpText="'Choose images files or drag and drop here'"
                  :errorText="{
                    type: 'Invalid file type. Only images Allowed',
                  }"
                  @select="filesSelected($event)"
                  @beforedelete="onBeforeDelete($event)"
                  @delete="fileDeleted($event)"
                  v-model="fileRecords"
                ></VueFileAgent>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="text-center">
                <v-btn
                  :disabled="!fileRecordsForUpload.length"
                  @click="uploadFiles()"
                  color="#2AB3A3"
                  dark
                  x-large
                >
                  Upload {{ fileRecordsForUpload.length }} files
                </v-btn>
              </v-col>
            </v-row>
          </v-card>

          <!-- <v-card class="pa-6" width="100%">
        <v-row>
          <v-col cols="12" md="12" sm="12" xs="12">
            <v-card outlined>
              <v-row no-gutters align="center">
                <v-col
                  cols="12"
                  md="11"
                  sm="11"
                  xs="11"
                  class="ma-4"
                  id="step-3"
                >
                  <span>รูปข่าว</span>
                  <span style="color: red">*</span>
                  <br />
                  <span style="color: red">อัพโหลดรูปภาพขนาด 500x250px</span>
                  <br />
                  <v-img :src="showImage" max-width="auto"></v-img>

                  <v-col cols="12" md="12" class="text-right">
                    <input
                      type="file"
                      accept="image/*"
                      id="file"
                      ref="q_1_1"
                      v-on:change="submitfileMain('q_1_1')"
                    />
                  </v-col>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card> -->

          <!-- <v-row align="center" justify="end" class="pa-6">
        <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
        <v-btn @click="submit('q_1_1')" dark color="#2AB3A3">บันทึก</v-btn>
      </v-row> -->
        </v-form>
      </v-tab-item>

      <v-tab-item class="pa-6">
        <v-form
          ref="createForm1"
          v-model="checkValidate1"
          :lazy-validation="lazy"
        >
          <v-card class="pa-6" width="100%">
            <v-tabs v-model="e2">
              <v-col
                cols="6"
                md="3"
                sm="3"
                :style="
                  e2 == 0
                    ? 'border-bottom: 3px solid #2ab3a3'
                    : 'border-bottom: 1px solid #2ab3a3'
                "
              >
                <v-tab class="textResponsive"> ภาษาไทย </v-tab>
              </v-col>
              <v-col
                cols="6"
                md="3"
                sm="3"
                :style="
                  e2 == 1
                    ? 'border-bottom: 3px solid #2ab3a3'
                    : 'border-bottom: 1px solid #2ab3a3'
                "
              >
                <v-tab class="textResponsive"> English </v-tab>
              </v-col>
              <v-col
                cols="6"
                md="6"
                sm="6"
                style="border-bottom: 1px solid #2ab3a3"
              >
              </v-col>
              <v-tabs-slider color="#2AB3A3"> </v-tabs-slider>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="6" sm="6">
                        <v-row>
                          <v-col cols="12" md="12" sm="12"
                            ><span class="pt-2">Keyword หลัก</span>
                            <span style="color: red">*</span></v-col
                          >
                          <v-col cols="12" md="12" sm="12">
                            <v-text-field
                              hide-details
                              v-model="form1.keyword_seo"
                              placeholder="ระบุ Keyword หลัก 1 คำที่ต้องการ SEO"
                              dense
                              outlined
                              :rules="rules.required"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="12" sm="12"
                            ><span class="pt-2">ชื่อหัวข้อ (TH)</span>
                          </v-col>
                          <v-col cols="12" md="12" sm="12">
                            <v-text-field
                              hide-details
                              v-model="form1.title_th_seo"
                              placeholder="ระบุชื่อหัวข้อ (TH)"
                              dense
                              outlined
                              :rules="rules.required"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="12" sm="12"
                            ><span class="pt-2">ชื่อลิ้งค์ URL</span>
                            <span style="color: red">*</span></v-col
                          >
                          <v-col cols="12" md="12" sm="12">
                            <v-text-field
                              hide-details
                              v-model="form1.url_seo"
                              placeholder="ระบุชื่อลิ้งค์ URL"
                              dense
                              outlined
                              :rules="rules.required"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="12" sm="12"
                            ><span class="pt-2">คำอธิบายย่อ (TH)</span>
                            <span style="color: red">*</span></v-col
                          >
                          <v-col cols="12" md="12" sm="12">
                            <v-textarea
                              v-model="form1.desc_th_seo"
                              placeholder="ระบุคำอธิบายย่อ (TH)"
                              dense
                              outlined
                              :rules="rules.required"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col cols="12" md="6" sm="6">
                        <v-card
                          color="#F5F5F5"
                          class="pa-6"
                          width="456px"
                          height="520px"
                        >
                          <v-row>
                            <v-col cols="12" md="12" sm="12">
                              <span class="text-h6"> SEO Checker </span>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" md="12" sm="12">
                              <v-icon color="red" class="mt-n2"
                                >mdi-close-circle-outline</v-icon
                              >
                              กำหนด Keyword หลัก
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-icon color="red" class="mt-n2"
                                >mdi-close-circle-outline</v-icon
                              >
                              มีคำ Keyword หลัก อยู่ในหัวข้อ
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-icon color="red" class="mt-n2"
                                >mdi-close-circle-outline</v-icon
                              >
                              มีคำ Keyword หลัก อยู่ในลิ้งค์ URL
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-icon color="red" class="mt-n2"
                                >mdi-close-circle-outline</v-icon
                              >
                              มีคำ Keyword หลัก อยู่ในคำอธิบายย่อ
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-icon color="red" class="mt-n2"
                                >mdi-close-circle-outline</v-icon
                              >
                              มีคำ Keyword หลัก อยู่ใน 10% แรกข้องเนื้อหา
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-icon color="red" class="mt-n2"
                                >mdi-close-circle-outline</v-icon
                              >
                              มีคำ Keyword หลัก ผสมอยู่ในเนื้อหาไม่ต่ำกว่า 5-10
                              ครั้ง
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-icon color="red" class="mt-n2"
                                >mdi-close-circle-outline</v-icon
                              >
                              มีคำ Keyword หลัก อยู่ในคำอธิบายย่อ
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-icon color="red" class="mt-n2"
                                >mdi-close-circle-outline</v-icon
                              >
                              เนื้อหามีการลิ้งค์ไปยังหน้าอื่นๆ ในเว็บไซต์นี้
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="6" sm="6">
                        <v-row>
                          <v-col cols="12" md="12" sm="12"
                            ><span class="pt-2">Keyword หลัก</span>
                            <span style="color: red">*</span></v-col
                          >
                          <v-col cols="12" md="12" sm="12">
                            <v-text-field
                              hide-details
                              v-model="form1.keyword_seo"
                              placeholder="ระบุ Keyword หลัก 1 คำที่ต้องการ SEO"
                              dense
                              outlined
                              :rules="rules.required"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="12" sm="12"
                            ><span class="pt-2">ชื่อหัวข้อ (EN)</span>
                          </v-col>
                          <v-col cols="12" md="12" sm="12">
                            <v-text-field
                              hide-details
                              v-model="form1.title_en_seo"
                              placeholder="ระบุชื่อหัวข้อ (EN)"
                              dense
                              outlined
                              :rules="rules.required"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="12" sm="12"
                            ><span class="pt-2">ชื่อลิ้งค์ URL</span>
                            <span style="color: red">*</span></v-col
                          >
                          <v-col cols="12" md="12" sm="12">
                            <v-text-field
                              hide-details
                              v-model="form1.url_seo"
                              placeholder="ระบุชื่อลิ้งค์ URL"
                              dense
                              outlined
                              :rules="rules.required"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="12" sm="12"
                            ><span class="pt-2">คำอธิบายย่อ (EN)</span>
                            <span style="color: red">*</span></v-col
                          >
                          <v-col cols="12" md="12" sm="12">
                            <v-textarea
                              v-model="form1.desc_en_seo"
                              placeholder="ระบุคำอธิบายย่อ (EN)"
                              dense
                              outlined
                              :rules="rules.required"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col cols="12" md="6" sm="6">
                        <v-card
                          color="#F5F5F5"
                          class="pa-6"
                          width="456px"
                          height="520px"
                        >
                          <v-row>
                            <v-col cols="12" md="12" sm="12">
                              <span class="text-h6"> SEO Checker </span>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" md="12" sm="12">
                              <v-icon color="red" class="mt-n2"
                                >mdi-close-circle-outline</v-icon
                              >
                              กำหนด Keyword หลัก
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-icon color="red" class="mt-n2"
                                >mdi-close-circle-outline</v-icon
                              >
                              มีคำ Keyword หลัก อยู่ในหัวข้อ
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-icon color="red" class="mt-n2"
                                >mdi-close-circle-outline</v-icon
                              >
                              มีคำ Keyword หลัก อยู่ในลิ้งค์ URL
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-icon color="red" class="mt-n2"
                                >mdi-close-circle-outline</v-icon
                              >
                              มีคำ Keyword หลัก อยู่ในคำอธิบายย่อ
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-icon color="red" class="mt-n2"
                                >mdi-close-circle-outline</v-icon
                              >
                              มีคำ Keyword หลัก อยู่ใน 10% แรกข้องเนื้อหา
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-icon color="red" class="mt-n2"
                                >mdi-close-circle-outline</v-icon
                              >
                              มีคำ Keyword หลัก ผสมอยู่ในเนื้อหาไม่ต่ำกว่า 5-10
                              ครั้ง
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-icon color="red" class="mt-n2"
                                >mdi-close-circle-outline</v-icon
                              >
                              มีคำ Keyword หลัก อยู่ในคำอธิบายย่อ
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-icon color="red" class="mt-n2"
                                >mdi-close-circle-outline</v-icon
                              >
                              เนื้อหามีการลิ้งค์ไปยังหน้าอื่นๆ ในเว็บไซต์นี้
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card>

          <!-- <v-col class="mt-5">
        <span style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          ">แกลลอรี่</span>
      </v-col> -->
        </v-form>
      </v-tab-item>
    </v-tabs>
  </v-row>
</template>
<script>
import moment from "moment";
import { Decode } from "@/services";
export default {
  data() {
    return {
      fileRecords: [],
      uploadUrl: "",
      uploadHeaders: { "X-Test-Header": "vue-file-agent" },
      fileRecordsForUpload: [],
      itemsUploadFile: [],
      showImageUpload: [],

      status: "เปิดใช้งาน",
      statusItems: [
        { id: 1, name: "เปิดใช้งาน" },
        {
          id: 2,
          name: "ปิดใช้งาน",
        },
        {
          id: 3,
          name: "ฉบับร่าง",
        },
      ],
      form: {
        title_en: "",
        title_th: "",
        short_desc_en: "",
        short_desc_th: "",
        full_desc_en: "",
        full_desc_th: "",
        img_url: "",
        type: 1,
        updatedAt: "",
      },
      form1: {
        keyword_seo_th: "",
        keyword_seo_en: "",
        title_seo_th: "",
        title_seo_en: "",
        url_seo_th: "",
        url_seo_en: "",
        desc_seo_th: "",
        desc_seo_en: "",
      },
      categoryDescription: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
      content: "",
      content1: "",
      editorOption: {
        placeholder: "ระบุข้อมูล",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.NewsData = JSON.parse(Decode.decode(localStorage.getItem("NewsData")));
    // console.log("this is current quill instance object", this.editor);
    this.getOne();
  },
  methods: {
    convertDate(val) {
      return moment(val).format("DD/MM/YYYY");
    },
    async getOne() {
      this.loading = true;
      this.userData = JSON.parse(
        Decode.decode(localStorage.getItem("gscmAdmin"))
      );
      console.log("this.userData", this.userData);
      const auth = {
        headers: {
          Authorization: `Bearer ${this.userData.token}`,
        },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/news/${this.NewsData.id}`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getNews", response.data.data);
      this.form1.title_seo_en = response.data.data.title_en;
      this.form1.title_seo_th = response.data.data.title_th;
      this.form.short_desc_en = response.data.data.short_desc_en;
      this.form.short_desc_th = response.data.data.short_desc_th;
      this.content1 = response.data.data.full_desc_en;
      this.content = response.data.data.full_desc_th;
      this.form.type = response.data.data.type;
      this.form.img_url = response.data.data.img_url;
      this.showImage = response.data.data.img_url;
      this.form.updatedAt = response.data.data.updatedAt;
      this.status = response.data.data.status;
      this.form1.keyword_seo_th = response.data.data.keyword_seo_th;
      this.form1.keyword_seo_en = response.data.data.keyword_seo_en;
      this.form1.url_seo_th = response.data.data.url_seo_th;
      this.form1.url_seo_en = response.data.data.url_seo_en;
      this.form1.desc_seo_th = response.data.data.desc_seo_th;
      this.form1.desc_seo_en = response.data.data.desc_seo_en;
      this.documentFilesNews = response.data.data.documentFilesNews;
      this.showImageUpload = response.data.data.pathPic;
      this.loading = false;
    },
    async handleFileUpload(questionNo) {
      // this.data[questionNo] = this.$refs[questionNo].files[0];
      // console.log("file", this.data[questionNo]);
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    onEditorChange1({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content1 = html;
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.pic1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageNews");
    },
    async submitfileMain(questionNo) {
      if (this.$refs[questionNo].files[0]) {
        let formData = new FormData();
        formData.append("files", this.$refs[questionNo].files[0]);
        formData.append("filename", this.$refs[questionNo].files[0].name);
        formData.append("type", "uploadfile");
        // formData.append("userId", this.userId);
        console.log("formData", formData);
        const auth = {
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Bearer` + " " + this.checkuser.token,
          },
        };
        console.log("auth", auth);
        const response = await this.axios.post(
          `https://gscm-service.yuzudigital.com/files/formData`,
          formData,
          auth
        );
        if (questionNo == "q_1_1") {
          this.form.img_url = response.data.data.path;
          this.showImage = response.data.data.path;
        }

        console.log("SUCCESS!!");
        console.log("response", response);
      }
    },
    async submit(pic) {
      console.log(this.file, "f");
      if (this.$refs.createForm.validate(true)) {
        // if (this.type == "CONTENT") {
        await this.submitfileMain(pic);
        console.log("pic", pic);
        // }
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("gscmAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          pathPic: this.showImageUpload,
          status: this.status,
          title_th: this.form1.title_seo_th,
          title_en: this.form1.title_seo_en,
          short_desc_th: this.form.short_desc_th,
          short_desc_en: this.form.short_desc_en,
          full_desc_th: this.content,
          full_desc_en: this.content1,
          img_url: this.form.img_url,
          type: this.form.type,
          // bannerUrl: this.bannerUrl,
        };
        console.log("data111", data);
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/news/${this.NewsData.id}`,
          data,
          auth
        );
        console.log("createBanner", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขข่าวและกิจกรรมคณะสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageNews");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },

    async pictureDelete(val) {
      this.showImageUpload.splice(val, 1);
    },
    async getUploadFile() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/atmospheres?courseId=${this.courseId}`,
        auth
      );
      console.log("getUploadFile", response.data.data);
      this.itemsUploadFile = response.data.data;
      this.showImageUpload = response.data.data[0].pathPic;
      console.log("showImageUpload", this.showImageUpload);
      this.loading = false;
    },
    async uploadFiles() {
      const auth = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      let pathPic = [];
      pathPic = this.showImageUpload;

      for (let i = 0; i < this.fileRecords.length; i++) {
        let formData = new FormData();
        formData.append("files", this.fileRecords[i].file);
        formData.append("filename", this.fileRecords[i].file.name);
        formData.append("type", "uploadfile");

        const response = await this.axios.post(
          `https://gscm-service.yuzudigital.com/files/formData`,
          formData,
          auth
        );
        pathPic.push({ path: response.data.data.path });
        console.log("pathPic", pathPic);
        console.log("fileRecords", this.fileRecords);
      }
      this.uploadFiles1(pathPic);
      this.fileRecords = [];
      this.fileRecordsForUpload = [];
    },
    async uploadFiles1(pathPic) {
      // console.log("itemsUploadFile", this.itemsUploadFile[0].id);
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const data = {
        courseId: this.courseId,
        pathPic: pathPic,
        fileRecords: this.fileRecords,
      };
      if (this.itemsUploadFile.length) {
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/atmospheres/${this.itemsUploadFile[0].id}`,
          data,
          auth
        );
        console.log("uploadPathPic", response);
        console.log(response.data.response_status);
      } else {
      }
    },
    deleteUploadedFile: function (fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
      );
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload =
        this.fileRecordsForUpload.concat(validFileRecords);
    },
    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        if (confirm("Are you sure you want to delete?")) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
  },
};
</script>

 